.cookie-consent {
    height: auto;
    margin: auto;
    box-sizing: content-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    z-index: 99;
}

.cookie-consent-inner-wrap {
    display: block;
    max-width: 540px;
    background: white;
    min-width: 480px;
    border-radius: 2px;
    box-shadow: 0px 0px 5px 2px black;
    padding: 0px;
    height: 320px;
    overflow-y: scroll;
    position: relative;
}

.co-consent-wrap {
    width: 100%;
    position: absolute;
    bottom: 0px;
    margin: 0px;
    background: rgba(0,0,0,0.6);
    height: 100%;
    z-index: 9;
}

.cookie-consent-info-wrap {
    padding: 20px;
    text-align: left;
}

div.cookie-consent-title {
    font-size: 1.2rem;
    padding: 20px;
}

div.cookie-consent-description {
    font-size: 0.9rem;
    padding-bottom: 3px;
    padding-top: 5px;
    font-weight: 300;
}

.cookie-consent-learn-more {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
}

div.cookie-consent-controls {
    font-size: 1rem;
    padding-left:20px;
    margin-top: 20px;
}

button.cookie-consent-btn {
    width: 46%;
    min-height: 40px;
    font-size: 12px;
    border: 1px solid #EAEAEA;
    outline: none;
    margin-right: 5px;
    padding: 5px;
    margin-bottom: 5px;
    background-color: #FAFAFA;
    cursor: pointer;
}

button.cookie-consent-controls-button-settings {
    
}

button.cookie-consent-controls-button-settings:hover {
    background-color: #EAEAEA;
}

button.cookie-consent-controls-button-accept {
    background-color: #2a2a2a;
    border: 1px solid #000000;
    color: white;
}

button.cookie-consent-controls-button-accept:hover {
    background-color: #3a3a3a;
}

.cookie-consent-types-wrap {
    font-size: 12px;
}

.cookie-consent-settings-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 100vh;
}

.cookie-consent-settings-title {
    font-size: 1.2rem;
    text-align: left;
    padding: 20px;
}

ul.cookie-consent-types {
    list-style-type: none;
    text-align: left;
    padding: 0px;
    margin: 0px;
}

ul.cookie-consent-types > li {
    display: block;
    width: 100%;
}

.co-consent-label {
    padding-top: 10px;
    font-size: 1rem;
    font-weight: 300;
    color: #232323;
}

.co-consent-label:hover {
    color: #535353;
}

.co-consent-category-divider {
    width: 100%;
    height: 1px;
    background: linear-gradient(90deg, rgba(162,162,162,1) 0%, rgba(162,162,162,0) 100%);
}

.cookie-consent-types-wrap {
    padding: 20px;
    padding-top: 0px;
}

.cookie-consent-type-info {
    font-weight: 300;
    font-size: 13px;
    padding: 10px 0px;
    padding-top: 5px;
    width: 90%;
}

.co-consent-label-and-switch-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.co-consent-required {
    max-width: 50px;
    margin-top: 5px;
}

/* Switch */

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 24px;
    margin-top: 5px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

/* Switch End */